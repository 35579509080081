import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  
  {
    path: 'events',
    loadChildren: './pages/events/events.module#EventsPageModule',
    canActivate: [AuthGuard]
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
   
  },

  { 
    path: 'events',
    children: [
      {
        path : '',
        loadChildren: './pages/events/events.module#EventsPageModule',
        canActivate: [AuthGuard]
      },

      {
        path : ':eventId',
        loadChildren: './pages/tickettypes/tickettypes.module#TickettypesPageModule',
        canActivate: [AuthGuard]
      }
      ]
  },

  {
    path: 'eventattendees',
    children: [
      {
        path : '',
        loadChildren: './pages/eventattendees/eventattendees.module#EventattendeesPageModule',
        canActivate: [AuthGuard]
      },
      {
        path : ':attnEventId',
        loadChildren: './pages/eventattendees/eventattendees.module#EventattendeesPageModule',
        canActivate: [AuthGuard]
      }
      ]
  },

  {
    path: 'eventattendeesmanualadd',
    children: [
      {
        path : ':attnEventId',
        loadChildren: () => import('./pages/eventattendeesmanualadd/eventattendeesmanualadd.module').then( m => m.EventattendeesmanualaddPageModule),
        canActivate: [AuthGuard]
        }
      ]
  },

  {
    path: 'attendees',
    children: [
      {
        path : ':ticketTypeId',
        loadChildren: './pages/attendees/attendees.module#AttendeesPageModule',
        canActivate: [AuthGuard]
        }
      ]
  },

  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canActivate: [AuthGuard]
  },

  { 
    path: 'myevents',
    children: [
      {
        path : '',
        loadChildren: () => import('./pages/myevents/myevents.module').then( m => m.MyeventsPageModule),
        canActivate: [AuthGuard]
      },

      {
        path : ':eventId',
        loadChildren: () => import('./pages/mytickets/mytickets.module').then( m => m.MyticketsPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },

  {
    path: 'createcoupon',
    children: [
      {
        path : ':productId',
        loadChildren: () => import('./pages/createcoupon/createcoupon.module').then( m => m.CreatecouponPageModule),
        canActivate: [AuthGuard]
        }
      ]
  },

  {
    path: 'createevent',
    loadChildren: () => import('./pages/createevent/createevent.module').then( m => m.CreateeventPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'createticket',
    loadChildren: () => import('./pages/createticket/createticket.module').then( m => m.CreateticketPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thankyouevent',
    loadChildren: () => import('./pages/thankyouevent/thankyouevent.module').then( m => m.ThankyoueventPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thankyouticket',
    loadChildren: () => import('./pages/thankyouticket/thankyouticket.module').then( m => m.ThankyouticketPageModule),
    canActivate: [AuthGuard]
  },

  { 
    path: 'editevent',
    children: [
      {
        path : '',
        loadChildren: () => import('./pages/editevent/editevent.module').then( m => m.EditeventPageModule),
        canActivate: [AuthGuard]
      },

      {
        path : ':eventId',
        loadChildren: () => import('./pages/formeventedit/formeventedit.module').then( m => m.FormeventeditPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  { 
    path: 'editticket',
    children: [
      {
        path : '',
        loadChildren: () => import('./pages/editticket/editticket.module').then( m => m.EditticketPageModule),
        canActivate: [AuthGuard]
      },

      {
        path : ':ticketId',
        loadChildren: () => import('./pages/formticketedit/formticketedit.module').then( m => m.FormticketeditPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  { 
    path: 'publishticket',
    children: [
      {
        path : '',
        loadChildren: () => import('./pages/publishticket/publishticket.module').then( m => m.PublishticketPageModule),
        canActivate: [AuthGuard]
      },

      {
        path : ':ticketId',
        loadChildren: () => import('./pages/formpublishticket/formpublishticket.module').then( m => m.FormpublishticketPageModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'updateprice',
    children: [
      {
        path : ':ticketTypeId',
        loadChildren: () => import('./pages/updateprice/updateprice.module').then( m => m.UpdatepricePageModule),
        canActivate: [AuthGuard]
        }
      ]
  },
  {
    path: 'thankyoueventedit',
    loadChildren: () => import('./pages/thankyoueventedit/thankyoueventedit.module').then( m => m.ThankyoueventeditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'thankyouticketedit',
    loadChildren: () => import('./pages/thankyouticketedit/thankyouticketedit.module').then( m => m.ThankyouticketeditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./pages/create/create.module').then( m => m.CreatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit',
    loadChildren: () => import('./pages/edit/edit.module').then( m => m.EditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myaccount',
    loadChildren: () => import('./pages/myaccount/myaccount.module').then( m => m.MyaccountPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'updatevendorinfo',
    loadChildren: () => import('./pages/updatevendorinfo/updatevendorinfo.module').then( m => m.UpdatevendorinfoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then( m => m.ResetpasswordPageModule)
  },
  {
    path: 'thankyouppublishticket',
    loadChildren: () => import('./pages/thankyouppublishticket/thankyouppublishticket.module').then( m => m.ThankyouppublishticketPageModule)
  },
  {
    path: 'deleteaccount',
    loadChildren: () => import('./pages/deleteaccount/deleteaccount.module').then( m => m.DeleteaccountPageModule)
  },
  {
    path: 'ordersuccess',
    loadChildren: () => import('./pages/ordersuccess/ordersuccess.module').then( m => m.OrdersuccessPageModule)
  },
  {
    path: 'editcoupon',
    loadChildren: () => import('./pages/editcoupon/editcoupon.module').then( m => m.EditcouponPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'couponlist',
    children: [
      {
        path : '',
        loadChildren: () => import('./pages/couponlist/couponlist.module').then( m => m.CouponlistPageModule),
        canActivate: [AuthGuard]
      },
      {
        path : ':couponId',
        loadChildren: () => import('./pages/editcoupon/editcoupon.module').then( m => m.EditcouponPageModule),
        canActivate: [AuthGuard]
      }
      ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
